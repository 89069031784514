import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Image,
  Grid,
  extendTheme, 
  Flex,
  Heading,
  HStack
} from '@chakra-ui/react';
import Header from './components/header';
import Footer from './components/footer';
import ImageSwiper from './components/swiper';
import Partner from './components/partner';
import svgImage from './C8C.svg';
import "@fontsource/pixelify-sans";

function App() {
 
  
  // 3. extend the theme
  const theme = extendTheme({
    config:{
      initialColorMode: 'dark',
      useSystemColorMode: false,
    },
     fonts: {
      heading: `'Pixelify Sans','Open Sans', sans-serif`,
      body: `'Pixelify Sans', SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace`,
    }
     })
     let images = ["wn1.jpg","cf1.jpg","wn2.jpg","cf2.jpg","wn3.jpg"]
     let partners = 
       {
         "Solana":"solana.webp",
         "Popheadz":"popheadz.webp",
         "Orca":"orca.webp",
         "Backpack":"backpack.webp",
         "Cubik":"cubik.webp",
         "Wormhole":"wormhole.webp",
         "Solarplex":"solarplex.webp"
       }
     
  
  return (
    <ChakraProvider theme={theme}>
      {/* <Header></Header> */}
      <Flex
          alignItems="center"
          justifyContent="center"
          mt="10%"
      >
            {/* <Heading>C8C Venture</Heading> */}
            <img src={svgImage} alt="Logo"  style={{ width: '50%', height: '50%' }} />


          </Flex>
            <Flex
             direction="column"
             ml="20%"
             mr="20%">
                  <Flex
                  alignItems="center"
                  >
                    <Text fontSize='2xl'>C8C&nbsp;</Text>   bases    on Chinese Feng Shui and Western numerology
                  </Flex>
                  <Flex>
                  - *Chinese Feng Shui:* The sound of "C" doesn't have a direct meaning, but it could be associated with clarity or communication.
                  </Flex>
                  <Flex>
                - *Western Numerology:* The letter "C" doesn't have a specific numerical value in standard numerology.
                  </Flex>
                  <Flex>
                  - *Chinese Feng Shui:* The number 8 is considered lucky and associated with wealth and prosperity.
                  </Flex>
                  <Flex>
                  - *Western Numerology:* In Western numerology, 8 is often linked to eternity success, abundance, and achievement.
                  
                  </Flex>
            </Flex>
            {/* <Flex
          alignItems="center"
          justifyContent="center"
          mt="10px"
      >
            <Heading>OUR VISION</Heading>
           
          </Flex>
            <Flex
               direction="row"
               mt="20px"
               alignItems="center"
               justifyContent="center"
             >
                <ImageSwiper images={images}></ImageSwiper>
            </Flex> */}
             {/* <Flex
              justifyContent="center"
              mt="10px"
              borderBottom="solid  1px;"
             >
               OUR PARTENER
             </Flex> */}
             {/* <Flex
          alignItems="center"
          justifyContent="center"
          mt="10px"
        >
            <Heading>OUR PARTENER</Heading>
          </Flex>
             <Flex
             mt="10px"
             mb="50px"
             >
               <Partner paterners={partners}></Partner>
             </Flex> */}
     
      {/* <Footer></Footer> */}
    </ChakraProvider>
  );
}

export default App;
